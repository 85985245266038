/*
 * Payment.js component styling
 */

#paypal-button {
  margin: 10px auto 0;
  width: 90%;
}

.apple-pay-button {
  margin: 10px auto;
  width: 90%;
  height: 45px;
  cursor: pointer;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: pay;
  /* Use any supported button type. */
}

#apple-wrapper {
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;
}

.apple-pay-button-black {
  -apple-pay-button-style: var(--black);
}

.apple-pay-button-white {
  -apple-pay-button-style: var(--white);
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.separator {
  position: relative;
}

.separator {
  align-items: center;
  text-align: center;
}

.separator .divider {
  margin-top: -22px;
}

.separator .or {
  background-color: white;
  padding: 10px;
  margin: auto;
  /* Center the "or" wording */
  display: inline-block;
  /* Ensure the "or" text is centered */
}

.signup-form-wrapper .promo-section {
  margin: 20px 0;
}

.promo-section .promo-submit {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.signup-form-wrapper .payment-title {
  font-family: "Figtree-Bold";
  font-size: 26px;
  margin: 20px 0 20px;
}

.hosted-field {
  border: 1px solid var(--lightgrey);
  height: 55px;
  border-radius: 4px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.hosted-field.invalid {
  border: 1px solid var(--red) !important;
}

.express-container {
  border: 1px solid var(--lightgrey);
  margin: 30px auto 10px;
  padding: 20px 10px;
  position: relative;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow:column wrap;
}

.express-container .express-title {
  display: block;
  background: var(--white);
  margin: auto;
  text-align: center;
  margin-top: -32px;
  width: 40%;
}

.payment-form .error {
  font-size: 13px;
  margin-bottom: 10px;
}

.payment-form .milstar-input {
  margin-top: 0;
  margin-bottom: 12px;
}

.payment-form .milstar-input .Mui-error:last-child,
.form-input .Mui-error:last-child {
  text-align: center;
  font-size: 13px;
  margin-top: 8px;
}

.login-wrapper {
  margin: 40px 0;
}

.selected-address {
  margin: 20px auto;
  padding-left: 10px;
}
.selected-address .header {
  font-family: "Figtree-Bold";
  text-align: left;
}

.selected-address h4 {
  margin: 10px 0;
  text-align: left;
  color: var(--blue);
}

.selected-address div {
  text-align: left;
}

.selected-address div span:first-of-type {
  display: inline-block;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .apple-pay-button,
  #paypal-button {
    width: 100%;
  }
  .promo-section {
    padding: 0 10px;
  }
  .promo-section .form-input {
    width: 260px;
  }
}
