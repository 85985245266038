.faq-container {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-flow:column;
    max-width:1200px;
    margin:0 auto;
    color:var(--grey);
}

.faq-container a {
    line-break:anywhere;
}

.faq {
    display:flex;
    width:100%;
    margin:10px 0;
}

.faq-container hr {
    color:var(--lightgrey);
    border:1px solid var(--lightgrey);
    border-style: solid;
}

.faq-header {
    display:flex;
    width:100%;
    align-items: center;
    justify-content:center;
    align-self:center;
    text-align: center;
    flex-flow:row wrap;
}

.faq-header-svg {
    display:inline-flex;
    width: 100%;
    justify-content:center;
    flex-flow:column wrap;
    align-items:center;
}

.faq-header-svg svg {
    padding: 0;
    margin: 30px 0;
    width: 450px;
    max-height: 50px;
}

.faq-header h1 {
    display:flex;
    color:var(--grey);
    align-self: center;
    font-size:38px;
}

.faq-category {
    display:flex;
    flex-flow:row wrap;
    border:solid 1px var(--lightgrey);
    border-radius:4px;
    overflow:hidden;
    margin:0 20px;
    width:100%;
}

.faq-item.question {
    display:flex;
    flex-flow:row;
    align-self: flex-start;
    width:100%;
    background-color:var(--white);
    padding:20px;
}

.faq-item.question:hover {
    cursor: pointer;
}

.faq-item.question h2 {
    flex-basis:70%;
}

.faq-item.answer {
    display:flex;
    flex-flow:column;
    padding: 25px 0px;
    margin: 0 30px;
    border-top:1px solid var(--lightgrey);
}

.faq-item.answer h3 {
    font-size:16px;
}

.faq-item.answer p {
    font-size:16px;
}

.faq-item.answer:first-child {
    border-top:none;
}

.faq-category p, .faq-category ul {
    width:100%;
}

.faq-category ul {
    box-sizing: border-box;
    list-style-type:disc;
    list-style-position: outside;
    margin-inline-start: 40px;
    padding: 10px 60px 0px 0;
}

.faq-category ol {
    box-sizing: border-box;
    list-style-type:decimal;
    list-style-position: outside;
    margin-inline-start: 40px;
    padding: 10px 60px 0px 0;
}

.faq-category ul li, .faq-category ol li {
    padding:5px 0;
}

.faq-arrow-wrapper {
    display:flex;
    flex-basis:30%;
    justify-content: flex-end;
}

.faq-arrow {
    color:var(--mediumgrey);
    width:25px;
    height:25px;
    transition: ease-out 200ms;
}

.selected {
    rotate:180deg;
}

.faq-item.question:has(.selected) {
    background-color:var(--lightblue);
}

@media screen and (max-width:767px) {
    .faq-header-svg svg {
        max-width:70%;
        max-height: 35px;
    }
    .faq-item.question {
        padding:10px;
        align-items: center;
        justify-content: center;
    }
    .faq-item.answer {
        padding:15px 0;
    }
    .faq-arrow-wrapper {
        flex-basis:15%;
        justify-content: center;
    }
    .faq-item.question h2 {
        flex-basis:85%;
        font-size:17px;
    }
    .faq-header h1 {
        font-size:26px;
    }
    .faq-category ul, .faq-category ol {
        margin-inline-start: 20px;
        padding:10px 30px 0px 0px;
    }
    .faq-header-svg svg {
        margin:20px 0;
    }
}