body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Museo100";
  src: url("../fonts/Museo_Slab_100.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Museo300";
  src: url("../fonts/Museo_Slab_300.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Museo500";
  src: url("../fonts/Museo_Slab_500.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Museo700";
  src: url("../fonts/Museo_Slab_700.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Museo900";
  src: url("../fonts/Museo_Slab_900.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Museo1000";
  src: url("../fonts/Museo_Slab_1000.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Black";
  src: url("../fonts/Figtree-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-BlackItalic";
  src: url("../fonts/Figtree-BlackItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Bold";
  src: url("../fonts/Figtree-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-BoldItalic";
  src: url("../fonts/Figtree-BoldItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-ExtraBold";
  src: url("../fonts/Figtree-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-ExtraBoldItalic";
  src: url("../fonts/Figtree-ExtraBoldItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Italic";
  src: url("../fonts/Figtree-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Light";
  src: url("../fonts/Figtree-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-LightItalic";
  src: url("../fonts/Figtree-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Medium";
  src: url("../fonts/Figtree-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-MediumItalic";
  src: url("../fonts/Figtree-MediumItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Regular";
  src: url("../fonts/Figtree-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: url("../fonts/Figtree-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Figtree-SemiBoldItalic";
  src: url("../fonts/Figtree-SemiBoldItalic.ttf");
  font-display: swap;
}
