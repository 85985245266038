.unavailable-address-wrapper {
  display: flex;
  justify-content: center;
}
.unavailable-address {
  border: 2px solid rgb(247, 200, 91); 
  padding: 12px;
  margin: 0 auto;
  width: 100%;
  text-align: center !important;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .unavailable-address-wrapper {
    min-width: 0.825rem;
  }
  .unavailable-address { 
    margin: 0 auto;
    width: 85% !important;
  }
  
}