.fiber-welcome-container p {
    font-size:16px;
    line-height:1.5em;
}

.footContent1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 13px;
    line-height:1.5em;
}

.footContent1 div {
    margin-right: 20px;
}

#welcome-header .sub-header {
    display: none;
}

#welcome-header header.App-header {
    background-color:transparent;
    padding:0;
}

.welcome-to {
    margin:0 auto;
    text-align:center;
    font-family:"Figtree-Regular";
    padding-top:30px;
    font-size:38px;
}

.wrapper.welcome {
    display:flex;
    flex-flow:column wrap;
    align-items: center;
    margin-top: 15px;
    margin-bottom:10px;
    padding: 40px 0;
    background-color: var(--lightblue)
}

.welcome-subhead-svg {
    display:inline-flex;
    width: 100%;
    justify-content:center;
    flex-flow:column wrap;
    align-items:center;
}

.welcome-subhead-svg svg {
    padding: 0;
    margin: 20px 0;
    width: 350px;
    max-height: 50px;
}

.welcome-subhead-svg.need-help svg {
    margin:20px 0 25px 0;
}

.what-youll-need-container {
    display: block;
    justify-items: center;
    width:1024px;
    margin: 0 auto;
}

.what-youll-need-devices-wrapper {
    flex-basis: 1024px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
}

.what-youll-need-wrapper {
    width: 700px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
}

.setup-in-seconds-container {
    background-color: var(--lightyellow);
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin-top: 40px;
    padding-bottom:40px;
}

.setup-in-seconds-wrapper {
    flex-basis: 900px;
    padding: 30px 0;
}

.need-help-wrapper {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    width: 100%;
}

.need-help-img {
    margin-top: 25px;
    width: 633px;
    height: 422px;
    align-self: center;
    background-size: contain;
    background-image: url('../images/Mother-Daughter-Photo.jpg');
    background-repeat: no-repeat;
}

.helpSubhead1 {
    display: inline-block;
}

.helpSubhead2 {
    display: none;
}

.steps-wrapper {
    display: inline-flex;
    flex-flow: row wrap;
    text-align: left;
    padding: 30px 0;
    justify-content: flex-start;
}

.setup-steps {
    flex-basis: 50%;
    height: 224px;
    margin-right: 40px;
}

.setup-image {
    flex-basis: auto;
}

.setup-image svg {
    height: 225px;
}

.setup-steps-header {
    font-size: 22px;
    font-family: "Figtree-Bold";
}

.setup-steps-description {
    font-size: 20px;
}

.setup-steps-subdescription {
    margin-top: 10px;
    font-size: 12px;
}

.setup-steps-numbers {
    width: 100%;
    display: inline-flex;
}

.setup-steps-numbers.number {
    height: 50px;
    width: 50px;
    margin: 15px 10px 15px 0;
}

.setup-steps-numbers.number:hover {
    cursor:pointer;
}

.setup-steps-numbers.number svg .st0{
    fill:#d9d9d9d9;
}

.setup-steps-numbers.number.active svg .st0 {
    fill:#D52B1E;
}

.wyn-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
}

.wyn-item.additional {
    padding:0px;
}

.wyn-item p {
    text-align: center;
}

.wyn-item.wifi svg {
    width: 65px;
}

.wyn-item.ethernet svg {
    width: 81px;
}

.wyn-item div {
    padding: 5px 0;
    font-family: "Figtree-Bold";
}

.wyn-item.devices {
    padding: 0;
    margin: 5px;
    flex-basis: 18%;
    font-family: "Figtree-Bold";
}

.device {
    min-width: 200px;
    min-height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.tplink {
    background-image: url('../images/TP-Link-Deco-W3600.png');
}

.eero {
    background-image: url('../images/Amazon-Eero-6.png');
    margin-right: 0;
}

.dlink {
    background-image: url('../images/D-Link-AX1500.png');
    margin-left: 0;
}

.googleac {
    background-image: url('../images/Google-AC1200.png');
}

.device-sticker1 {
    display: inline;
    flex-basis: 12%;
}

.device-sticker2 {
    display: none;
}

.welcome-footer {
    display: flex;
    flex-flow: column wrap;
    padding:20px 20px 30px 20px;
    align-items: center;
    font-size:13px;
  }
  
  .welcome-footer img {
    width: 150px;
    margin-bottom:10px;
  }
  

@media screen and (max-width: 767px) {

    .fiber-welcome-container p {
        padding:0 20px;
        text-align:center;
        line-height:1.25em;
    }

    .footContent1 {
        flex-flow: column wrap;
        text-align:center;
    }

    .footContent1 div {
        margin: 5px 0;
    }

    .container.welcome {
        width: 100%;
    }

    .what-youll-need-container {
        width: 100%;
    }

    .steps-wrapper {
        width: 100%;
        justify-content: center;
        flex-flow: column wrap;
        margin-top:0;
        padding-top:0;
    }

    .setup-steps {
        flex-basis: 100%;
        min-height: 260px;
        margin: 30px;
    }

    .setup-image {
        align-self: center;
        height: auto;
    }

    .setup-image svg {
        height: 250px;
    }

    .device {
        min-width: 200px;
        min-height: 135px;
    }

    .wyn-item div {
        font-size: 15px;
    }

    .need-help-img {
        margin: 0;
        background-position: center;
        background-size: contain;
        background-clip: content-box;
        width: 85%;
        height: 265px;
    }

    .welcome-subhead-svg svg {
        max-height: 35px;
    }

    .helpSubhead1 {
        display: none;
    }

    .helpSubhead2 {
        width: auto;
        display: block;
        padding: 0 30px;
        text-align:center;
    }

    .device-sticker1 {
        display: none;
    }

    .device-sticker2 {
        display: inline-flex;
        height: 65px;
        flex-basis: 100%;
        margin-top: 10px;
        justify-content: center;
        z-index: 10;
    }

    .device-sticker1 svg,
    .device-sticker2 svg {
        width: auto;
        height: 110px;
    }

    .wrapper.welcome {
        width: auto;
        margin: 0;
        padding: 0;
        text-align:center;
    }

    #welcome-header header.App-header {
        padding-top:0;
    }

    #welcome-header header.App-header .fiberLogo {
        height:50px;
        max-width:85%;
    }

    .welcome-to {
        text-align:"center";
        font-family:"Figtree-Regular";
        font-size: 22px;
        padding-top:20px;
    }

    .wrapper.welcome h5 {
        line-height:1em;
        margin-bottom:7px;
        font-size:22px;
    }

    .container .wrapper.welcome {
        padding: 20px;
        margin-top:18px;
    }

    .what-youll-need-wrapper {
        width: 100%;
    }

    .wyn-item {
        width: 38%;
        justify-content:flex-start;
        text-align:center;
    }

    .wyn-item.additional {
        width: 100%;
        font-size: 16px;
    }

    .wyn-item svg {
        max-height: 80px;
        margin-top: 0;
    }
}