/*
 * StayTuned.js component styling
 */

.stay-tuned {
  text-align: center;
}

.stay-tuned .stay-tuned-yeah-girl {
  background: url("../images/YEAH-Girl-Wide.jpg") no-repeat top center;
  background-size: cover;
  height: 460px;
}
.stay-tuned .stay-tuned-title {
  margin: 20px auto 60px;
  text-align: center;
  font-size: 22px;
  width: 85%;
}
.stay-tuned .stay-tuned-img {
  margin-top: -60px;
  width: 250px;
}

@media screen and (max-width: 767px) {
  .stay-tuned .stay-tuned-yeah-girl {
    height: 340px;
  }
  .stay-tuned .stay-tuned-img {
    width: 200px;
  }
  .stay-tuned .fiber-logo {
    margin-top: 50px;
  }
}
