/*
 * NotAvailable.js component styling
 */

.not-available {
  text-align: center;
}

.not-available .title {
  font-size: 24px;
  font-family: "Figtree-Bold";
  margin: 30px 0;
}

.not-available .subtitle {
  font-size: 22px;
  width: 40%;
  margin: 0 auto 40px;
}

.phoneNum {
  padding-left: 135px;
}

@media screen and (max-width: 767px) {
  .not-available .title {
    font-size: 16px;
    margin: inherit;
    margin-bottom: 20px;
  }
  .not-available .subtitle {
    font-size: 16px;
    width: 100%;
  }
}
