/*
* SignupForm.js component styling
*/

.signup-youin-container {
  text-align: center;
}
.signup-youin-container .yeah-girl-img-wrapper {
  background: url("../images/YEAH-Girl-2.jpg") no-repeat top center;
  background-size: cover;
  height: 460px;
}

.signup-youin-container .youre-in-title {
  justify-content: center;
  font-family: "Figtree-ExtraBold";
  font-size: 22px;
  margin: 20px auto;
  text-align: center;
}

.signup-youin-container .youre-in-description {
  margin: -10px 0 32px 0;
  font-size: 22px;
  line-height: 1;
  text-align: center;
}

.signup-youin-container .youre-in-img {
  margin-top: -60px;
  width: 250px;
}

.signup-youin-container .down-arrow-img {
  margin: 40px 0;
  cursor: pointer;
}
.router-desc-container {
  overflow-y: scroll;
}  

.router-desc-wrapper {
  background-color: #f7f0e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 14px 20px 28px;

  top: 50%;
  left: 50%;
  /* min-width: 30%; */
  width: 600px;
  max-width: 700px;
}

.router-desc-wrapper .router-desc {
  margin: 12px 40px;
  color: #000;
  line-height: 1.2; 
}

.router-desc-wrapper .router-desc .router-desc-title {
  margin-top: 18px;
  margin-bottom: 0px;
  padding: 2 auto;
}

.router-desc-wrapper .router-desc-title {
  font-family: "Figtree-Bold";
  display: block;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #000;
}

.router-desc-wrapper .setup-header-img {
  width: 80%;
  height: 25px;
  max-width: 725px;
  margin-bottom: 24px;
}

.specs-list-wrapper {
  width: 100%;

}
.specs-list {
  list-style-type: disc;
  font-size: 16px;
}
.specs-list .specs-list-item {
  list-style-type: disc;
  margin: 0 40px;
}

.specs-list-wrapper .specs-list-title {
  font-family: "Figtree-Bold";
  margin: 0 40px;
  padding: 0;
}

.sticker-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin: 6px 0 18px 0;
}

.works-with-fiber-img {
  height: 135px;
  margin-right: 10px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

.look-for-this-sticker-img {
  width: fit-content;
  margin-bottom: 20px;
  height: 65px;
}

.signup-form-wrapper {
  width: 450px;
  margin: 0px auto 50px auto;
  position: relative;
}

.signup-form-wrapper .create-account-title {
  font-size: 26px;
  text-align: left;
  font-family: "Figtree-Bold";
  margin: 20px 0 20px;
}

/* Styles for Small Screens */
@media screen and (max-width: 767px) {
  .signup-form-wrapper {
    width: 100%;
  }

  .user-info {
    padding: 0 10px;
  }

  .signup-youin-container .yeah-girl-img-wrapper {
    background: url("../images/YEAH-Girl-Mobile.jpg") no-repeat top center;
    background-size: cover;
    height: 250px;
  }

  .signup-youin-container .youre-in-img {
    width: 150px;
  }
  .signup-youin-container .youre-in-title {
    margin: 20px;
    font-size: 17px;
    width: 90%;
    text-align: left;
    line-height: 1.1;
  }
  .signup-youin-container .down-arrow-img {
    margin: 20px 0;
  }
  
  .signup-youin-container .youre-in-description {
    margin: 0 20px 26px;
    font-size: 17px;
    padding-left: 0;
    text-align: left;
    line-height: 1.1;
  }

  .router-desc-container .router-desc-wrapper {
    height: min-content;
    width: 83%;
    margin: 0px;
    padding: 6px 8px;
  }  

  .router-desc-wrapper .router-desc .router-desc-title {
    margin-top: 18px; 
  }
  
  .router-desc-wrapper .router-desc {
    line-height: 1.15;
    padding: 4px auto;
    margin-bottom: 20px;
    margin: 14px;
    font-size: 14px;
  }
  
  .router-desc-wrapper .setup-header-img img {
    display: none;
  }
  
  .router-desc-wrapper .setup-header-img {
    background: url("../images/setup-in-seconds-savings-subhead-mobile.svg")
      no-repeat center;
    margin-bottom: 8px;
    height: 50px;
  }

  .works-with-fiber-img {
    height: 100px;
    margin-right: 10px;
  }

  .look-for-this-sticker-img {
    width: 34%;
    margin: 0 14px 20px 0;
    height: 50px;
  }
  
  .specs-list-wrapper {
    font-size: 14px;
  }
  .specs-list-wrapper .specs-list {
    margin-bottom: 6px;
  }
  .specs-list-wrapper .specs-list-title {
    font-size: 14px;
    margin: 0 14px;
  }
  .specs-list .specs-list-item {
    margin-left: 14px;
  }
}

/* Styles for Medium Screens */
@media (min-width: 600px) and (max-width: 960px) {
  .signup-youin-container .youre-in-title {
    margin: 20px 40px;
    line-height: 1.1;
    font-size: 18px;
    text-align: center;
  }
  .signup-youin-container .youre-in-description {
    margin: 20px 40px 24px;
    line-height: 1.1;
    font-size: 17px;
    text-align: center;
  }
  
  .router-desc-wrapper .setup-header-img img {
    display: none;
  }
  
  .router-desc-wrapper .setup-header-img {
    width: 80%;
    background: url("../images/setup-in-seconds-savings-subhead-mobile.svg")
    no-repeat center;
    height: 75px;
    margin: 14px auto;
  }
}